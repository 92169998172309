import React, { useEffect, useState } from "react"
import { Link, navigate } from "gatsby"

export const TabHeader = ({
  data,
  desktopCol,
  mobileCol,
  itemCenter,
  addClass,
  id,
}) => {
  useEffect(() => {
    const tabs = document.querySelectorAll(
      `${id ? `#${id}` : ""}[data-tab-target]`
    )
    const tabContents = document.querySelectorAll(
      `${id ? `#${id}` : ""}[data-tab-content]`
    )
    const tabFunction = tab => {
      const target = document.querySelector(tab.dataset.tabTarget)

      tabContents.forEach(tabContent => {
        tabContent.classList.remove("active")
      })

      tabs.forEach(tab => {
        tab.classList.remove("active")
      })

      tab.classList.add("active")
      target.classList.add("active")
    }

    tabs.forEach(tab => {
      tab.addEventListener("click", tabFunction)
    })

    const slider = document.querySelector(`${id ? `#${id}` : ""}.nav-tabs`)
    let isDown = false
    let startX
    let scrollLeft
    const setIsDownFalse = () => {
      isDown = false
    }
    const mouseDown = e => {
      isDown = true
      startX = e.pageX - slider.offsetLeft
      scrollLeft = slider.scrollLeft
    }
    const mouseMove = e => {
      if (!isDown) return
      e.preventDefault()
      const x = e.pageX - slider.offsetLeft
      const walk = (x - startX) * 1 //scroll-fast
      slider.scrollLeft = scrollLeft - walk
    }
    if (data.length >= 4) {
      slider.addEventListener("mousedown", mouseDown)
      slider.addEventListener("mouseleave", setIsDownFalse)
      slider.addEventListener("mouseup", setIsDownFalse)
      slider.addEventListener("mousemove", mouseMove)
    }

    return () => {
      tabs.forEach(tab => {
        tab.removeEventListener("click", tabFunction)
      })

      if (data.length >= 4) {
        slider.removeEventListener("mousedown", mouseDown)
        slider.removeEventListener("mouseleave", setIsDownFalse)
        slider.removeEventListener("mouseup", setIsDownFalse)
        slider.removeEventListener("mousemove", mouseMove)
      }
    }
  })

  const desktopColumns = desktopCol ? `col-md-${desktopCol}` : `col-md`
  const mobileColumns = mobileCol ? `col-${mobileCol}` : `col`
  const center = itemCenter ? "align-items-center" : "report-pl-lg"
  const className = addClass ? addClass : ""

  return (
    <section className="report">
      <div className="report-tabs">
        <ul
          id={id}
          className="row flex-row flex-nowrap scroll-horizontal nav nav-tabs"
          role="tablist"
        >
          {data.map((item, i) => {
            return (
              <li
                key={item.name}
                className={`${mobileColumns} ${
                  data.length < 4 ? "col-md" : desktopColumns
                } d-flex flex-column ${center} ${className}`}
                // data-tab-target={`#${item.target}`}
              >
                <a
                  className={`btn btn-link fx-underline ${
                    i === 0 ? "active" : ""
                  }`}
                  data-toggle="tab"
                  href={`#${item.target}`}
                  role="tab"
                  aria-controls={`${item.target}`}
                  aria-selected="true"
                >
                  {item.name}
                </a>
              </li>
            )
          })}
        </ul>
      </div>
    </section>
  )
}

export const TabLink = ({
  data,
  desktopCol,
  mobileCol,
  itemCenter,
  addClass,
  path,
  id,
}) => {
  const desktopColumns = desktopCol ? `col-md-${desktopCol}` : `col-md`
  const mobileColumns = mobileCol ? `col-${mobileCol}` : `col`
  const center = itemCenter ? "align-items-center" : "report-pl-lg"
  const className = addClass ? addClass : ""
  return (
    <section className="report">
      <div className="report-tabs">
        <ul
          id={id}
          className="row flex-row flex-nowrap scroll-horizontal nav nav-tabs"
          role="tablist"
        >
          {data.map((item, i) => {
            return (
              <li
                key={item.name}
                className={`${mobileColumns} ${
                  data.length < 4 ? "col-md" : desktopColumns
                } d-flex flex-column ${center} ${className}`}
                onClick={() => navigate(item.target)}
                // data-tab-target={`#${item.target}`}
              >
                <Link
                  className={`btn btn-link fx-underline ${
                    path === item.target ? "active" : ""
                  }`}
                  data-toggle="tab"
                  to={`${item.target}`}
                  role="tab"
                  aria-controls={`${item.target}`}
                  aria-selected="true"
                >
                  {item.name}
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    </section>
  )
}

export const TabLinkPrecious = ({
  data,
  desktopCol,
  mobileCol,
  itemCenter,
  addClass,
  path,
  id,
  onTierChange,
}) => {
  const desktopColumns = desktopCol ? `col-md-${desktopCol}` : `col-md`
  const mobileColumns = mobileCol ? `col-${mobileCol}` : `col`
  const center = itemCenter ? "align-items-center" : "report-pl-lg"
  const className = addClass ? addClass : ""
  return (
    <section className="container p-0 tier-wrapper">
      <div className="tier-tabs">
        <ul
          id={id}
          className="row flex-row flex-nowrap scroll-horizontal nav nav-tabs"
          role="tablist"
        >
          {data.map((item, i) => {
            return (
              <li
                key={item.name}
                id={`nav-tier-${item.name}`}
                className={`${mobileColumns} ${
                  data.length < 4 ? "col-md" : desktopColumns
                } d-flex flex-column ${center} ${className}`}
                onClick={() => {
                  onTierChange(item.name)
                  navigate(item.target)
                }}
              >
                <Link
                  className={`btn btn-link fx-underline ${
                    path === item.target ? "active" : ""
                  }`}
                  data-toggle="tab"
                  to={`${item.target}`}
                  role="tab"
                  aria-controls={`${item.target}`}
                  aria-selected="true"
                >
                  <img src={item.sourceUrl} />
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    </section>
  )
}
